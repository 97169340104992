import { rolesConstants } from './roles'
import { isFeatureEnabled } from './featureFlags'

export const checkRelationRoleAndLinks = (authenticationReducer, link) => {
  const role = authenticationReducer.hasOwnProperty('user')
    ? authenticationReducer.user.role
    : ''

  const roleLinkList = [
    {
      link: '/Usuarios',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/usersMenuCollapse',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/home',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/login',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/users',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/scooters/locations',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/scooters/:id',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    ...(!isFeatureEnabled('REACT_APP_FF_SHOW_VEHICLES_V2')
      ? [
          {
            link: '/scooters',
            role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
          },
        ]
      : []),
    {
      link: '/statistics',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/customers',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/firmwares/create',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/firmwares/edit/:id',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/firmwares/:id',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/firmwares',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/services',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/reports',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/analytics',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/OemAnalytics',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/assignScooter',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/notifications',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/OEM',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/OemAnalytics',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/newSilence',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/monthlyDetails',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/batteryCheck',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/deletedUsers',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/invoices',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/subscriptionsMenuCollapse',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/subscriptionPlans',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    {
      link: '/subscriptionList',
      role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
    },
    ...(isFeatureEnabled('REACT_APP_FF_SHOW_SUBMODELS_V2')
      ? [
          {
            link: '/submodels',
            role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
          },
        ]
      : []),
    ...(isFeatureEnabled('REACT_APP_FF_SHOW_VEHICLES_V2')
      ? [
          {
            link: '/vehicles',
            role: [rolesConstants.MANAGER_ADMIN, rolesConstants.SHARING_ADMIN],
          },
        ]
      : []),
  ]

  let result = []
  roleLinkList.map((rl) => {
    if (rl.link === link) {
      rl.role.filter((r) => r === role && result.push(r))
    }
  })

  return result.length > 0
  // return true
}
