import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import Tooltip from '@material-ui/core/Tooltip'
import AppsIcon from '@material-ui/icons/Apps'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import DescriptionIcon from '@material-ui/icons/Description'
import StationIcon from '@material-ui/icons/EvStation'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PeopleIcon from '@material-ui/icons/Group'
import HomeIcon from '@material-ui/icons/Home'
import LanguageIcon from '@material-ui/icons/Language'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { connect } from 'react-redux'

import { AssessmentRounded, Motorcycle } from '@material-ui/icons'
import { CurrencyExchange, ElectricBike, FactCheck } from '@mui/icons-material'
import { checkRelationRoleAndLinks } from '../../helpers/drawerList'

const ListItems = ({
  t,
  isCollapseUsersMenuOpen,
  isCollapseSubscriptionsMenuOpen,
  setIsCollapseUsersMenuOpen,
  setIsCollapseSubscriptionsMenuOpen,
  authenticationReducer,
}) => {
  const location = useLocation()
  return authenticationReducer.pendingSelectUserRole ? (
    <div></div>
  ) : (
    <div>
      {checkRelationRoleAndLinks(authenticationReducer, '/home') && (
        <Tooltip title={t('dashboard.items.home')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/home"
            style={{
              background: location.pathname === '/home' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <HomeIcon
                style={{
                  color: location.pathname === '/home' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.home')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/usersMenuCollapse',
      ) && (
        <Tooltip
          title={t('dashboard.items.usersManagement')}
          placement="right"
          arrow
        >
          <ListItem
            button
            onClick={() => setIsCollapseUsersMenuOpen((prev) => !prev)}
            style={{
              background:
                location.pathname === '/usersMenuCollapse'
                  ? '#e41622'
                  : 'inherit',
            }}
          >
            <ListItemIcon>
              <PeopleIcon
                style={{
                  color:
                    location.pathname === '/usersMenuCollapse'
                      ? 'white'
                      : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.usersManagement')}
            />
            {isCollapseUsersMenuOpen ? (
              <ExpandLess style={{ color: '#e41622' }} />
            ) : (
              <ExpandMore style={{ color: '#e41622' }} />
            )}
          </ListItem>
        </Tooltip>
      )}

      <Collapse in={isCollapseUsersMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {checkRelationRoleAndLinks(authenticationReducer, '/customers') && (
            <Tooltip
              title={t('dashboard.items.customers')}
              placement="right"
              arrow
            >
              <ListItem
                button
                component={Link}
                to="/customers"
                style={{
                  background:
                    location.pathname === '/customers' ? '#e41622' : 'inherit',
                }}
              >
                <ListItemIcon>
                  <ArrowForwardIosIcon
                    style={{
                      color:
                        location.pathname === '/customers'
                          ? 'white'
                          : '#e41622',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: 'white' }}
                  primary={t('dashboard.items.customers')}
                />
              </ListItem>
            </Tooltip>
          )}

          {checkRelationRoleAndLinks(authenticationReducer, '/OEM') && (
            <Tooltip title={t('dashboard.items.oem')} placement="right" arrow>
              <ListItem
                button
                component={Link}
                to="/OEM"
                style={{
                  background:
                    location.pathname === '/OEM' ? '#e41622' : 'inherit',
                }}
              >
                <ListItemIcon>
                  {/* <AddToQueueIcon color={"primary"} /> */}
                  <ArrowForwardIosIcon
                    style={{
                      color: location.pathname === '/OEM' ? 'white' : '#e41622',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: 'white' }}
                  primary={t('dashboard.items.oem')}
                />
              </ListItem>
            </Tooltip>
          )}

          {checkRelationRoleAndLinks(authenticationReducer, '/newSilence') && (
            <Tooltip
              title={t('dashboard.items.signSilence')}
              placement="right"
              arrow
            >
              <ListItem
                button
                component={Link}
                to="/newSilence"
                style={{
                  background:
                    location.pathname === '/newSilence' ? '#e41622' : 'inherit',
                }}
              >
                <ListItemIcon>
                  {/* <PersonAddIcon color={"primary"} /> */}
                  <ArrowForwardIosIcon
                    style={{
                      color:
                        location.pathname === '/newSilence'
                          ? 'white'
                          : '#e41622',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: 'white' }}
                  primary={t('dashboard.items.signSilence')}
                />
              </ListItem>
            </Tooltip>
          )}

          {checkRelationRoleAndLinks(authenticationReducer, '/Usuarios') && (
            <Tooltip title={t('dashboard.items.users')} placement="right" arrow>
              <ListItem
                button
                component={Link}
                to="/users"
                style={{
                  background:
                    location.pathname === '/users' ? '#e41622' : 'inherit',
                }}
              >
                <ListItemIcon>
                  <ArrowForwardIosIcon
                    style={{
                      color:
                        location.pathname === '/users' ? 'white' : '#e41622',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: 'white' }}
                  primary={t('dashboard.items.users')}
                />
              </ListItem>
            </Tooltip>
          )}
        </List>
      </Collapse>

      {checkRelationRoleAndLinks(authenticationReducer, '/firmwares') && (
        <Tooltip title={t('dashboard.items.firmwares')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/firmwares"
            style={{
              background:
                location.pathname === '/firmwares' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <AppsIcon
                style={{
                  color:
                    location.pathname === '/firmwares' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.firmwares')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/scooters/locations',
      ) && (
        <Tooltip title={t('dashboard.items.locations')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/scooters/locations"
            style={{
              background:
                location.pathname === '/scooters/locations'
                  ? '#e41622'
                  : 'inherit',
            }}
          >
            <ListItemIcon>
              <LanguageIcon
                style={{
                  color:
                    location.pathname === '/scooters/locations'
                      ? 'white'
                      : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.locations')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/scooters') && (
        <Tooltip title={t('dashboard.items.vehicles')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/scooters"
            style={{
              background:
                location.pathname === '/scooters' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <MotorcycleIcon
                style={{
                  color:
                    location.pathname === '/scooters' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.vehicles')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/statistics') && (
        <Tooltip
          title={t('dashboard.items.statistics')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/statistics"
            style={{
              background:
                location.pathname === '/statistics' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <AssessmentRounded
                style={{
                  color:
                    location.pathname === '/statistics' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.statistics')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/monthlyDetails') && (
        <Tooltip
          title={t('dashboard.items.monthlyDetails')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/monthlyDetails"
            style={{
              background:
                location.pathname === '/monthlyDetails' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <DescriptionIcon
                style={{
                  color:
                    location.pathname === '/monthlyDetails'
                      ? 'white'
                      : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.monthlyDetails')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/batteryCheck') && (
        <Tooltip
          title={t('dashboard.items.batteryCheck')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/batteryCheck"
            style={{
              background:
                location.pathname === '/batteryCheck' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <StationIcon
                style={{
                  color:
                    location.pathname === '/batteryCheck' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.batteryCheck')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/deletedUsers') && (
        <Tooltip title={'Deleted Users'} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/deletedUsers"
            style={{
              background:
                location.pathname === '/deletedUsers' ? '#e41622' : 'inherit',
            }}
          >
            <ListItemIcon>
              <PeopleIcon
                style={{
                  color:
                    location.pathname === '/deletedUsers' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={'Deleted Users'}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/invoices') && (
        <Tooltip title={t('invoices.title')} placement="right" arrow>
          <ListItem
            button
            style={{
              background:
                location.pathname === '/invoices' ? '#e41622' : 'inherit',
            }}
            onClick={() => (window.location.href = '/invoice')}
          >
            <ListItemIcon>
              <FactCheck
                style={{
                  color:
                    location.pathname === '/invoices' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('invoices.title')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/subscriptionsMenuCollapse',
      ) && (
        <Tooltip
          title={t('dashboard.items.subscriptions')}
          placement="right"
          arrow
        >
          <ListItem
            button
            onClick={() => setIsCollapseSubscriptionsMenuOpen((prev) => !prev)}
            style={{
              background:
                location.pathname === '/usersMenuCollapse'
                  ? '#e41622'
                  : 'inherit',
            }}
          >
            <ListItemIcon>
              <CurrencyExchange
                style={{
                  color:
                    location.pathname === '/usersMenuCollapse'
                      ? 'white'
                      : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.subscriptions')}
            />
            {isCollapseSubscriptionsMenuOpen ? (
              <ExpandLess style={{ color: '#e41622' }} />
            ) : (
              <ExpandMore style={{ color: '#e41622' }} />
            )}
          </ListItem>
        </Tooltip>
      )}

      <Collapse
        in={isCollapseSubscriptionsMenuOpen}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {checkRelationRoleAndLinks(
            authenticationReducer,
            '/subscriptionPlans',
          ) && (
            <Tooltip
              title={t('dashboard.items.subscriptionPlans')}
              placement="right"
              arrow
            >
              <ListItem
                button
                style={{
                  background:
                    location.pathname === '/subscriptionPlans'
                      ? '#e41622'
                      : 'inherit',
                }}
                onClick={() =>
                  (window.location.href = '/subscription/subscription-plan')
                }
              >
                <ListItemIcon>
                  <ArrowForwardIosIcon
                    style={{
                      color:
                        location.pathname === '/subscriptionPlans'
                          ? 'white'
                          : '#e41622',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: 'white' }}
                  primary={t('dashboard.items.subscriptionPlans')}
                />
              </ListItem>
            </Tooltip>
          )}
        </List>
        <List component="div" disablePadding>
          {checkRelationRoleAndLinks(
            authenticationReducer,
            '/subscriptionList',
          ) && (
            <Tooltip
              title={t('dashboard.items.subscriptionList')}
              placement="right"
              arrow
            >
              <ListItem
                button
                style={{
                  background:
                    location.pathname === '/subscriptionList'
                      ? '#e41622'
                      : 'inherit',
                }}
                onClick={() => (window.location.href = '/subscription/list')}
              >
                <ListItemIcon>
                  <ArrowForwardIosIcon
                    style={{
                      color:
                        location.pathname === '/subscriptionList'
                          ? 'white'
                          : '#e41622',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: 'white' }}
                  primary={t('dashboard.items.subscriptionList')}
                />
              </ListItem>
            </Tooltip>
          )}
        </List>
      </Collapse>

      {checkRelationRoleAndLinks(authenticationReducer, '/submodels') && (
        <Tooltip title={t('dashboard.items.submodels')} placement="right" arrow>
          <ListItem
            button
            style={{
              background:
                location.pathname === '/submodels' ? '#e41622' : 'inherit',
            }}
            onClick={() => (window.location.href = '/submodels')}
          >
            <ListItemIcon>
              <ElectricBike
                style={{
                  color:
                    location.pathname === '/submodels' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.submodels')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/vehicles') && (
        <Tooltip title={t('dashboard.items.vehicles')} placement="right" arrow>
          <ListItem
            button
            style={{
              background:
                location.pathname === '/vehicles' ? '#e41622' : 'inherit',
            }}
            onClick={() => (window.location.href = '/vehicles')}
          >
            <ListItemIcon>
              <Motorcycle
                style={{
                  color:
                    location.pathname === '/vehicles' ? 'white' : '#e41622',
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.vehicles')}
            />
          </ListItem>
        </Tooltip>
      )}
    </div>
  )
}
function mapState(state) {
  const { authenticationReducer, operatorReducer } = state
  return { authenticationReducer, operatorReducer }
}

export default connect(mapState, null)(ListItems)
